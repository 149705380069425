@font-face {
	font-family: "USBankCircular";
	src:url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Light.woff2") format("woff2"),
		url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
  }
  @font-face {
	font-family: "USBankCircular";
	src:url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Regular.woff2") format("woff2"),
		url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Regular.woff") format("woff");
		font-weight: normal;
		font-style: normal;
		font-display: swap;
  }
  @font-face {
	font-family: "USBankCircular";
	src:url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Bold.woff2") format("woff2"),
		url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
  }
  @font-face {
	font-family: "USBankCircular";
	src:url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Black.woff2") format("woff2"),
		url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Black.woff") format("woff");
		font-weight: 900;
		font-style: normal;
		font-display: swap;
  }

body { 
	margin: 0; 
	padding: 0; 
	font-family: "USBankCircular", arial, "Roboto", sans-serif;
	font-size: 14px; 
	background-color: #fbfbfb; 
  } 
  .btn { 
	margin: 0 10px !important; 
	padding: 12px !important; 
  } 
  tbody { 
	tr:hover { 
	  background: whitesmoke; 
	} 
  } 
  a { 
	text-decoration: none; 
  } 
  .loader { 
	&.padding &.centered { 
	  display: flex; 
	  justify-content: center; 
	  align-items: center; 
	} 
  } 
  .no-padding { 
	padding: 0 !important; 
  } 
  .welcome-message { 
	font-size: 25px; 
	margin: 1rem 0rem 2rem 0rem; 
	color: #000; 
  } 
  .item-count { 
	font-size: 25px; 
	font-weight: bold; 
	font-stretch: normal; 
	font-style: normal; 
	line-height: normal; 
	color: #000; 
  } 
  .list-header { 
	display: flex; 
	justify-content: space-between; 
	align-items: center; 
	margin: 1rem 0rem; 
  } 
  .button-normal { 
	font-weight: 400 !important; 
	font-stretch: normal; 
	font-style: normal; 
	letter-spacing: -0.05px !important; 
	opacity: 0.8; 
  } 
  ul.error { 
	padding: 0; 
	padding-left: 1rem; 
	li { 
	  margin: 0.5rem 0rem; 
	} 
  } 
  .inputLabel { 
	color: #000; 
	font-size: 14px; 
	font-weight: bold; 
	margin: 0.5rem 0; 
	display: block; 
	color: #000;
  } 
  .inputField { 
	border: solid 1px #dfe1ef; 
	background-color: #fff; 
  } 
  .react-datepicker-wrapper { 
	width: calc(100% - 1rem); 
	input { 
	  cursor: pointer; 
	} 
  } 
  .react-datepicker {
	font-family: 'USBankCircular';
  }
  .flex-container { 
	display: flex; 
	&.vertical-center { 
	  align-items: center; 
	} 
	&.horizontal-center { 
	  justify-content: center; 
	} 
  } 
  .full-width { 
	width: 100%; 
  } 
  .full-height { 
	height: 100%; 
  } 
  .black { 
	color: #222; 
  } 
  .white { 
	color: #fff; 
  } 
  .list-title { 
	font-size: 16px; 
	letter-spacing: normal; 
	color: #000; 
	font-weight: bold;

  } 
  .file-status { 
	font-size: 14px; 
	font-weight: normal; 
	font-stretch: normal; 
	font-style: normal; 
	line-height: 1.43; 
	letter-spacing: -0.05px; 
	text-align: center; 
	width: 140px; 
	display: block; 
	padding: 5px 0px;
	&.rejected { 
	  opacity: 1; 
	  background-color: #d8667f; 
	  color: #842438; 
	} 
	&.processed, 
	&.approved { 
	  opacity: 0.87; 
	  background-color: #53caa7; 
	  color: #1b4c3e; 
	} 
	&.initiated, 
	&.pending { 
	  opacity: 0.87; 
	  background-color: rgba(235, 166, 97, 0.72); 
	  color: #1b4c3e; 
	} 
  } 
  .MuiInputBase-root { 
	svg { 
	  path { 
		color: rgba(0, 0, 0, 0.87); 
	  } 
	} 
  } 
  .MuiTableSortLabel-root:hover {
	color: #000 !important;
  }
  .MuiDialog-container button {
	border: none !important;
  }
  .MuiDialog-container {
	border: none !important;
	.MuiButton-root.MuiButton-containedPrimary:hover {
		color: #fff;
		background-color: #0558FF;
	}
  }