.addUserWrapper {
  // font-family: Roboto;
  color: #000;
  .heading {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
  }
  .userForm {
    #phoneNumber {
      width: 182px;
    }
    .mandatory {
      color: red;
      margin: 0 3px;
    }
    .hyperlink {
      // font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.05px;
      text-decoration: underline;
      color: #1c4b6b;
      margin: 9px 0 0 0;
      a:visited {
        color: #1c4b6b;
      }
      a:hover {
        color: #1c4b6b;
      }
      a:active {
        color: #1c4b6b;
      }
    }
    span.countryCode.preTextFied {
      line-height: 58px;
      vertical-align: middle;
      font-size: 18px;
      padding: 16.7px 10px 10.2px 9px;
      border-right: none !important;
      border: solid 1px #dfe1ef;
  }
    label.errorMessage {
      display: block;
      color: red;
      margin: 4px 0px 0 0;
  }
    .btns {
      // margin: 30px 26px 0 0;
      border-radius: 1px;
      // box-shadow: 0 1px 3px 2px rgba(96, 148, 177, 0.26);
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: -0.05px;
      text-align: center;
      padding: 8px 40px;
      // text-transform: uppercase;
      width: 108px;
    }
    .save {
      // background-color: #1c4b6b;
      color: #ffffff;
    }
    .cancel {
      background-color: #ffffff;
      color: #1c4b6b;
    }
    input[type="text"],
    input[type="number"],
    input[type="password"] {
      padding: 19px 10px 15px 10px;
      width: 222px;
    }
    select {
      padding: 20px 0px 15px 24px;
      width: 220px;
      border-radius: 0;
      border: solid 1px #9ebdcf;
    }
    select::after,
    select::before {
      border: none;
    }
  }
}
