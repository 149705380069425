.root {
    background-color: #969798;
    background-size: cover;
    background-position: 'center';
    background-repeat: no-repeat;
    width: 100vm;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}